import React from 'react'
import * as PropTypes from 'prop-types'
import styled from 'styled-components'
import ContactForms from '../../forms/contact-forms'
import useBreakpoints from '../../hooks/use-breakpoints'
import ArrowLink from '../../ui/arrow-link'
import FullScreenModal from '../full-screen-modal/full-screen-modal'
import CtaBox from '../landing/landing-cta-box'
import PortfolioCarousel from './portfolio-carousel'
import PreviousNext from './previous-next'

const RootContainer = styled.div`
  display: flex;
  > div {
    display: flex;
    flex: 1;
    flex-direction: column;
    height: 100vh;
    overflow: hidden;
    &:first-child {
      ${props => props.theme.media.sm`
        display: none;
      `};
    }
  }
`

const Right = styled.div``

const ImageContainer = styled.div`
  height: 73vh;
  flex: none;
  position: relative;
  background: black;
  display: flex;
  ${Right} & {
    ${props => props.theme.media.sm`
      height: 50vh;
    `};
  }
`

const CtaContainer = styled.div`
  height: 27vh;
  flex: none;
  ${Right} & {
    display: none;
    ${props => props.theme.media.sm`
      display: block;
    `};
  }
`

const HeaderContainer = styled.div`
  display: flex;
  flex: none;
  justify-content: flex-end;
  padding: 1vmax;
  font-size: 1rem;
`

const ScrollContainer = styled.div`
  padding-top: 3vw;
  overflow: auto;
  flex: 1;
  display: flex;
  flex-direction: column;
  ${props => props.theme.media.sm`
    padding-top: 0;
  `};
`

const BodyContainer = styled.div`
  padding: 1vmax 5vmax;
  flex: 1;
  ${props => props.theme.media.sm`
    padding: 1vmax 2vmax;
  `};
`

const ProjectDescription = styled.div`
  font-size: 0.7rem;
  color: ${p => p.theme.text.black[200]};
  display: flex;
  justify-content: space-between;
`

const TitleWrapper = styled.div`
  margin: 3vmax 0 4vmax;
  text-align: center;
`

const Title = styled.h1`
  font-size: 3.2rem;
  text-transform: uppercase;
  margin-bottom: 0.5rem;
`

const Location = styled.div`
  font-size: 0.85rem;
  color: ${p => p.theme.text.black[200]};
`

const Subtitle = styled.div`
  text-transform: uppercase;
  font-size: 1rem;
  font-weight: 800;
`

const WorkTitle = styled.span`
  color: ${p => p.theme.text.black[100]};
  text-transform: uppercase;
  font-size: 0.9rem;
`

const Body = styled.div`
  margin: 3vmax 0;
`

const Text = styled.div`
  color: ${p => p.theme.text.black[200]};
  font-size: 0.95rem;
  line-height: 1.9rem;
  margin: 0.5vmax 0;
  white-space: pre-wrap;
`

const Info = styled.div`
  display: flex;
  ${props => props.theme.media.sm`
    flex-direction: column;
  `};
`

const Key = styled(Subtitle)`
  flex: none;
  width: 30%;
  text-align: left;
  line-height: 1.9rem;
`

const Value = styled(Text)`
  margin: 0;
  flex: 1;
  text-align: left;
  font-size: 0.9rem;
  > a {
    font-style: italic;
    color: #5a7dfd;
  }
`
const Section = styled.div`
  margin-bottom: 3vmax;
`

const PortfolioFullScreen = ({
  project,
  onNext,
  onPrevious,
  nextProjectName,
  previousProjectName,
  closeModal,
}) => {
  const topRef = React.useRef(null)
  const { xs, sm } = useBreakpoints()
  const isDevice = xs || sm
  return (
    <RootContainer>
      <div>
        {isDevice || (
          <ImageContainer>
            <PortfolioCarousel
              ourProduct={project.ourProduct}
              images={project.images}
              descriptions={project.imgDescription}
              darkTheme={project.darkTheme}
            />
          </ImageContainer>
        )}
        <CtaContainer>
          <FullScreenModal
            withHeader
            color="secondary"
            trigger={
              <CtaBox
                text="PROJECT"
                hoverText="START A PROJECT"
                sideTextOrientation="horizontal"
                sideText="If you have a project write to us"
                color="secondary"
              />
            }
          >
            <ContactForms formName="project" />
          </FullScreenModal>
        </CtaContainer>
      </div>
      <Right>
        <HeaderContainer>
          <ArrowLink
            label="back to portfolio"
            color="primary"
            gradient
            backwards
            onClick={closeModal}
            autoFocus
          />
        </HeaderContainer>
        <ScrollContainer>
          <div ref={topRef} />
          {isDevice && (
            <ImageContainer>
              <PortfolioCarousel
                images={project.images}
                ourProduct={project.ourProduct}
                descriptions={project.imgDescription}
                darkTheme={project.darkTheme}
              />
            </ImageContainer>
          )}
          <BodyContainer>
            <ProjectDescription>
              <div>{project.job}</div>
              <div>{project.duration}</div>
            </ProjectDescription>
            <TitleWrapper>
              <Title>{project.company}</Title>
              <Location>{project.location}</Location>
            </TitleWrapper>

            <Body>
              <Section>
                <Subtitle>{`About ${project.company}`}</Subtitle>
                <Text>{project.description}</Text>
              </Section>
              <Section>
                <Subtitle>Our Work</Subtitle>
                {project.work.map(({ title, details }) => (
                  <Text key={title}>
                    <WorkTitle>{title}</WorkTitle>
                    {` - ${details}`}
                  </Text>
                ))}
              </Section>
              {project.info.map(({ key, value }) => (
                <Info key={key}>
                  <Key key={key}>{key}</Key>
                  <Value>
                    <a href={value} target="_blank" rel="noopener noreferrer">
                      {value}
                    </a>
                  </Value>
                </Info>
              ))}
            </Body>
          </BodyContainer>
          <CtaContainer>
            <FullScreenModal
              withHeader
              color="secondary"
              trigger={
                <CtaBox
                  text="PROJECT"
                  hoverText="START A PROJECT"
                  sideTextOrientation="horizontal"
                  sideText="If you have a project write to us"
                  color="secondary"
                />
              }
            >
              <ContactForms formName="project" />
            </FullScreenModal>
          </CtaContainer>
          {project.company !== nextProjectName && (
            <PreviousNext
              nextProjectName={nextProjectName}
              previousProjectName={previousProjectName}
              onNext={() => {
                onNext()
                topRef.current.scrollIntoView({ behavior: 'smooth' })
              }}
              onPrevious={() => {
                onPrevious()
                topRef.current.scrollIntoView({ behavior: 'smooth' })
              }}
            />
          )}
        </ScrollContainer>
      </Right>
    </RootContainer>
  )
}

PortfolioFullScreen.propTypes = {
  closeModal: PropTypes.func,
  project: PropTypes.object,
  onNext: PropTypes.func,
  onPrevious: PropTypes.func,
  nextProjectName: PropTypes.string,
  previousProjectName: PropTypes.string,
}

export default PortfolioFullScreen
