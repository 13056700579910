import React from 'react'
import { Carousel } from 'react-responsive-carousel'
import styled from 'styled-components'
import GatsbyImage from 'gatsby-image'
import TechpadBadgeIcon from '../../images/techpad-badge'
import ArrowButton from '../../ui/arrow-button'

const StyledImage = styled(GatsbyImage)`
  width: 100%;
  height: 100%;
  opacity: 0.95;
`

const StyledArrow = styled(ArrowButton)`
  opacity: 0.5;
  position: absolute;
  align-self: center;
  left: 3%;
  z-index: 9;
  font-size: 1.7rem;
  ${props => props.theme.media.sm`
    font-size: 2rem;
  `};
  ${p => !p.backwards && 'right: 3%; left: unset;'};
  svg {
    ${p => p.backwards && 'transform: rotate(180deg)'};
  }
  background: ${p =>
    !p.white ? 'rgba(0, 0, 0, 0.3)' : 'rgba(255, 255, 255, 0.3)'};
  :hover {
    opacity: 0.7;
    background: ${p =>
      !p.white ? 'rgba(0, 0, 0, 0.4)' : 'rgba(255, 255, 255, 0.4)'};
  }
  transition: background 300ms ease-in-out;
  path {
    fill: ${p => (p.white ? 'white' : 'black')};
  }
`

const Description = styled.div`
  position: absolute;
  left: 2rem;
  top: 2rem;
  padding: 1.2rem 3.5rem;
  background: white;
  border-radius: 2.5rem;
  text-transform: uppercase;
  font-weight: bolder;
  font-size: 1rem;
  box-shadow: 1px 1px 3px 1px
    ${p => (!p.white ? 'rgba(0, 0, 0, 0.2)' : 'rgba(255, 255, 255, 0.2)')};
  ${props => props.theme.media.sm`
    left: 50%;
    transform: translate(-50%);
    white-space: nowrap;
  `};
  ${props => props.theme.media.xs`
    left: 20px;
    transform: none;
  `};
`

const OurBadge = styled(TechpadBadgeIcon)`
  width: 70px;
  height: 87px;
  position: absolute;
  top: -6px;
  right: 30px;
  z-index: 9;
  ${props => props.theme.media.xs`
    width: 62px;
    height: 77px;
    right: 10px;
  `};
`

const PortfolioCarousel = ({ images, darkTheme, descriptions, ourProduct }) => {
  const [imgIndex, setImgIndex] = React.useState(0)
  const carouselRef = React.useRef(null)

  const carousel = React.useMemo(
    () => (
      <Carousel
        className={`portfolio-carousel ${darkTheme ? 'dark' : 'light'}`}
        ref={carouselRef}
        {...(images.length > 1 && {
          interval: 5000,
          autoPlay: true,
          infiniteLoop: true,
          stopOnHover: true,
          onChange: setImgIndex,
        })}
        showIndicators={images.length > 1}
        centerMode
        showThumbs={false}
        showStatus={false}
        showArrows={false}
        centerSlidePercentage={100}
        emulateTouch
      >
        {images.map(image => (
          <StyledImage fluid={image} key={image.src} />
        ))}
      </Carousel>
    ),
    [images],
  )

  return (
    <>
      {carousel}
      {ourProduct && <OurBadge />}
      {images.length > 1 && descriptions[imgIndex] && (
        <>
          <Description white={darkTheme}>{descriptions[imgIndex]}</Description>
          <StyledArrow
            backwards
            onClick={() =>
              carouselRef.current && carouselRef.current.onClickPrev()
            }
            white={darkTheme}
          />
          <StyledArrow
            onClick={() =>
              carouselRef.current && carouselRef.current.onClickNext()
            }
            white={darkTheme}
          />
        </>
      )}
    </>
  )
}

export default PortfolioCarousel
