import React from 'react'
import styled from 'styled-components'
import useProjects from '../../hooks/use-projects'
import CircleButton from '../../ui/circle-button'
import Filter from '../../ui/filter'
import FullScreenModal from '../full-screen-modal/full-screen-modal'
import PortfolioFullScreen from '../portfolio/portfolio-full-screen'
import PortfolioItem from './landing-portfolio-item'
import { Link } from 'gatsby'
import Image from 'gatsby-image'
import CtaBox from './landing-cta-box'

const StyledCtaBox = styled(CtaBox)`
  ${props => props.theme.media.sm`
    min-height: 72vmin;
  `};
`

const GridContainer = styled.div`
  display: grid;
  grid-gap: 20px;
  grid-template-columns: repeat(2, 1fr);
  ${props => props.theme.media.sm`
    grid-template-columns: repeat(1, 1fr);
  `};
`

const StyledImage = styled(Image)`
  width: 100%;
  height: 100%;
  opacity: 0.95;
`

const Title = styled.div`
  font-size: 1.8rem;
  text-transform: uppercase;
  text-align: center;
  margin-bottom: 3vw;
  font-weight: bold;
  ${props => props.theme.media.xs`
      font-size: 1.2rem;
  `};
`

const StyledFilter = styled(Filter)`
  width: 60%;
  margin: 6vmax auto 4vmax;
  ${props => props.theme.media.sm`
    width: 84%;
    grid-template-rows: repeat(3, 1fr);
    grid-template-columns: repeat(2, 1fr);
    > *:first-child {
      grid-column: 1 / span 2;
    }
  `};
`

const ShowMore = styled(CircleButton)`
  font-size: 1.5rem;
  margin: 4rem auto 0;
  background: ${p => p.theme.gradients.primary.main};
  position: relative;
  :before {
    content: '';
    background: ${props => props.theme.gradients.primary.hovered};
    opacity: 0;
    border-radius: 50%;
    position: absolute;
    top: 0;
    width: 100%;
    height: 100%;
    transition: opacity 0.4s ease-in-out;
  }
  &:hover:not(:disabled),
  &:focus {
    background: ${p => p.theme.gradients.primary.main};
    &:before {
      opacity: 1;
    }
  }
  > div {
    font-size: 0.5em;
  }
`

const FILTERS = ['blockchain', 'product', 'web', 'mobile']

const LandingPortfolio = ({ limit, withFilters, withTitle }) => {
  const { filters, controls } = useProjects({ limit, FILTERS })

  const filtersData = ['all', ...FILTERS].map(i => ({
    title: i,
    matches: filters.results.length,
  }))

  return (
    <>
      {withTitle && <Title>RECENT WORK</Title>}
      {withFilters && (
        <StyledFilter
          filters={filtersData}
          onClick={filters.setFilter}
          activeFilter={filters.active}
        />
      )}
      <GridContainer>
        {filters.results.map((project, i) => (
          <PortfolioItem
            project={project}
            setProject={controls.set}
            i={i}
            key={i}
          >
            {project.images[0] && <StyledImage fluid={project.images[0]} />}
          </PortfolioItem>
        ))}
        {!withFilters && (
          <Link to="/portfolio/">
            <StyledCtaBox
              text="PORTFOLIO"
              hoverText="VISIT PORTFOLIO"
              sideTextOrientation="horizontal"
              sideText="Check our portfolio"
              color="primary"
            />
          </Link>
        )}
        {!!controls.projects.current.project && (
          <FullScreenModal open onClose={controls.close}>
            <PortfolioFullScreen
              project={controls.projects.current.project}
              nextProjectName={controls.projects.nextProject.company}
              previousProjectName={controls.projects.previousProject.company}
              onNext={controls.next}
              onPrevious={controls.previous}
            />
          </FullScreenModal>
        )}
      </GridContainer>
      {withFilters && filters.toShow > 0 && (
        <ShowMore variant="contained" onClick={filters.showAll}>
          +{filters.toShow}
        </ShowMore>
      )}
    </>
  )
}

export default LandingPortfolio
