import React from 'react'
import styled from 'styled-components'
import TechpadBadgeIcon from '../../images/techpad-badge'
import ArrowLink from '../../ui/arrow-link'

const Overlay = styled.div`
  cursor: pointer;
  position: absolute;
  width: 100%;
  height: 100%;
  background-color: ${props => props.theme.palette.secondary};
  display: flex;
  justify-content: center;
  opacity: 0;
  transition: opacity 200ms ease-in-out;
`

const OverlayButton = styled(ArrowLink)`
  position: absolute;
  top: 25%;
  font-size: 1rem;
  ${props => props.theme.media.sm`
      top: calc(20% - 20px);
      transform: translateY(-25%);
  `};
`

const Wrapper = styled.section`
  display: flex;
  position: relative;
  justify-content: center;
  background-color: black;
  height: 100%;
  :hover {
    ${Overlay} {
      z-index: 1;
      opacity: 1;
    }
  }
`

const PortfolioItemDetails = styled.div`
  background-color: white;
  position: absolute;
  bottom: 20px;
  height: 40%;
  width: 50%;
  z-index: 1;
  border-radius: 5px;
  display: flex;
  flex-direction: column;
  ${props => props.theme.media.sm`
    width: 75%;
    height: 60%;
  `}
`
const NameWrapper = styled.div`
  margin: auto;
`

const ProjectName = styled.div`
  font-size: 1.7rem;
  font-weight: bold;
  text-transform: uppercase;
`
const ProjectLocation = styled.div`
  margin-top: 0.2rem;
  font-size: 0.8rem;
  text-align: center;
  color: ${p => p.theme.text.black[200]};
`

const ProjectDescription = styled.div`
  font-size: 0.85rem;
  color: ${p => p.theme.text.black[200]};
  display: flex;
  justify-content: space-between;
  position: absolute;
  bottom: 0;
  width: 100%;
  padding: 1rem;
`

const OurBadge = styled(TechpadBadgeIcon)`
  width: 25%;
  height: 35%;
  position: absolute;
  top: -1px;
  right: 2%;
`

const LandingPortfolioItem = ({ children, project, setProject, i }) => (
  <Wrapper>
    <Overlay onClick={() => setProject(i)}>
      <OverlayButton label="learn more" color="secondary" />
    </Overlay>
    {children}
    <PortfolioItemDetails>
      {project.ourProduct && <OurBadge />}
      <NameWrapper>
        <ProjectName>{project.company}</ProjectName>
        <ProjectLocation>{project.location}</ProjectLocation>
      </NameWrapper>

      <ProjectDescription>
        <div>{project.job}</div>
        <div>{project.duration}</div>
      </ProjectDescription>
    </PortfolioItemDetails>
  </Wrapper>
)

export default LandingPortfolioItem
