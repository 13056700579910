import React from 'react'
import styled from 'styled-components'
import useBreakpoints from '../../hooks/use-breakpoints'
import ArrowLink from '../../ui/arrow-link'
import CtaBox from '../landing/landing-cta-box'

const PreviousNextContainer = styled.div`
  display: flex;
  flex: none;
  height: 27vh;
  ${props => props.theme.media.sm`
    height: 15vh;
    > div {
      cursor: pointer;
      flex: 1;
      display: flex;
      button {margin: auto;}
    }
  `};
`

const SmallCtaBox = styled(CtaBox)`
  font-size: 1.2rem;
`

const PreviousNext = ({
  nextProjectName,
  previousProjectName,
  onNext,
  onPrevious,
}) => {
  const { xs, sm } = useBreakpoints()
  const sameNext = nextProjectName === previousProjectName
  return (
    <PreviousNextContainer>
      {xs || sm ? (
        <>
          {!sameNext && (
            <div onClick={onPrevious}>
              <ArrowLink
                label={previousProjectName}
                backwards
                color="primary"
                gradient
              />
            </div>
          )}
          <div onClick={onNext}>
            <ArrowLink
              label={nextProjectName}
              color={sameNext ? 'primary' : 'secondary'}
              gradient
            />
          </div>
        </>
      ) : (
        <>
          {!sameNext && (
            <SmallCtaBox
              backwards
              text={previousProjectName}
              hoverText={previousProjectName}
              sideTextOrientation="horizontal"
              sideText="Previous"
              color="primary"
              onClick={onPrevious}
            />
          )}
          <SmallCtaBox
            text={nextProjectName}
            hoverText={nextProjectName}
            sideTextOrientation="horizontal"
            sideTextPosition="bottom-right"
            sideText="Next"
            color={sameNext ? 'primary' : 'secondary'}
            onClick={onNext}
          />
        </>
      )}
    </PreviousNextContainer>
  )
}

export default PreviousNext
